.pricing-card {
    position: relative;
    li{
        padding:10px 0;
        &:first-child{
            padding-top: 0;
        }
        &:last-child{
            border-bottom: none !important;
            padding-bottom: 0;
        }
    }
}
@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;