.event-form {
  &-header {
    margin-bottom: 20px;

    h1 {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &-registration {
    margin-bottom: 20px;
  }

  &-invoice {
    &-header {
      h3 {
        margin-bottom: 10px;
      }
    }

    &-total {
      display: flex;
      margin-top: 10px;
      flex-direction: column;
    }

    &-button {
      margin-top: 20px;
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;