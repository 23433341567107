@card-width: 350px;

.event-card {
  width: @card-width;
  .event-card-image {
    width: @card-width;
  }

  .event-name {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
    max-width: @card-width;
  }

  .event-item-wrapper {
    display: flex;
    align-items: center;
  }

  .event-items {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .event-price-description {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .event-price-rating {
    margin-bottom: 5px;

    .rating-value {
      font-weight: 400;
    }
    .rating-description {
      margin-left: 5px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;