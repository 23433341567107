.login-page-template-1 {
  .form-content-wrapper {
    padding: 50px 20% 50px 20%;
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    // background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    height: 100vh;

    // display: flex;
    // align-items: center;
    // justify-content: center;

    .logo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .login-logo {
        height: 100px;
      }
    }

    .login-title {
      font-size: 30px;
      text-align: center;
      margin-bottom: 10px;
    }
    .login-sub-title {
      font-size: 12px;
      text-align: center;
      margin-bottom: 30px;
    }

    .login-field {
      &::placeholder {
        font-size: 12px;
      }

      .ant-input {
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;