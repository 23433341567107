#footer-wrapper{
    position: relative;
    background: #010c16;
    color:rgb(238, 237, 237);
    font-size: 0.9rem;
    text-align: center;
    padding:40px 0;
    margin:0;
    overflow: hidden;
    a {
        color:rgb(190, 187, 187);
        font-weight: bold;
        &:hover{
            color:#87c1f8;
        }
    }
}
@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;