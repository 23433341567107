.price-list {
  // background-color: red;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &-type {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    margin-bottom: 0;
  }

  &-schedule {
    &-body {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    &-title {
      color: #808080;
      font-weight: 400;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding-left: 20px;

      p {
        font-weight: 600;
        margin-bottom: 2px;
      }
    }

    &-condition {
      text-indent: 20px;
      font-weight: 600;
    }
  }
}

@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;