.cardEvent{
    .post-card-body{
        padding: 0 10px;
        h2{
            font-weight: 700;
            font-size: 1.1rem;
            margin-bottom: 20px;
        } 
        overflow: hidden;
        .post-card-information{
            list-style: none;
            margin: 0 0 20px;
            padding: 0;
            font-size: 0.8rem;
            color:#03383a;
            li{
                margin-bottom: 7px;
                &:last-child{
                    margin-bottom: 0;
                }

            .icon{
                margin-right: 5px;
            }
            }
        }
    }
    &:hover{
        h2 {
            color:#1466d4;
        }
    }
    .ant-card-body{
        padding:15px
    }
}
@btn-primary-bg: #071E59;@text-color: #071E59;@menu-highlight-color: #071E59;@primary-color: #fa8c15;@font-size-base: 12px;@font-family: 'Inter', sans-serif;@height-base:  30px;@height-lg:  38px;@height-sm:  22px;@form-item-margin-bottom: 14px;@form-vertical-label-padding:  0 0 6px;@border-radius-base: 8px;@border-radius-sm: 8px;